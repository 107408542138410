import { createTheme } from '@mui/material/styles';

// assets
import colors from '../assets/scss/_themes-vars.module.scss';

// project imports
import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';
import themeTypography from './typography';
import themeComponent from './component';

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const theme = (customization) => {
	const color = colors;
	const DEFAULT_BORDERRADIUS = '0.6rem';
	const DEFAULT_SPACE = '0.5rem';

	const themeOption = {
		// MUI 테마 색상 재정의
		colors: color,
		heading: color.grey450,
		paper: color.paper,
		page: color.page,
		backgroundDefault: '#F7F8FA',
		background: color.primaryLight,
		darkTextPrimary: color.grey750,
		darkTextSecondary: color.grey450,
		textDark: color.grey750,
		menuSelected: color.primaryMain,
		menuSelectedBack: color.page,
		divider: color.grey100,

		defaultBorderRadius: DEFAULT_BORDERRADIUS,
		defaultSpace: DEFAULT_SPACE,

		customization,
	};

	const themeOptions = {
		direction: 'ltr',
		palette: themePalette(themeOption),
		// mixins: {
		// 	toolbar: {
		// 		minHeight: '48px',
		// 		padding: '16px',
		// 		'@media (min-width: 600px)': {
		// 			minHeight: '48px',
		// 		},
		// 	},
		// },
		typography: themeTypography(themeOption),
		component: themeComponent(themeOption),
	};

	const themes = createTheme(themeOptions);
	themes.components = componentStyleOverrides(themeOption);

	return themes;
};

export default theme;
