import { Button as MuiButton } from '@mui/material';

/**
 * 버튼 공통 컴포넌트
 * @param {string} children 버튼 내부 텍스트
 * @param {Tag} startIcon 버튼내부 좌측에 표시될 아이콘
 * @param {boolean} isAble disable인지 able인지 boolean값
 * @param {boolean} isVisible 버튼의 표시 여부
 * @param {boolean} isComplete 완료상태 (좌측아이콘 체크 or 연필상태 구분)
 * @param {boolean} isTab 현재 탭 상태
 * @param {boolean} isSelect 선택 상태
 * @param {object} handleClick 클릭 시 수행 될 함수
 * @param {text} props 기타 props
 */
function Button({
	children,
	startIcon,
	isAble = true,
	isVisible = true,
	isComplete = false,
	isTab = false,
	isSelect = false,
	handleClick,
	...props
}) {
	/****************************************************************************************************
	 *
	 * styles, dispatch
	 *
	 ****************************************************************************************************/

	/****************************************************************************************************
	 *
	 * state
	 *
	 ****************************************************************************************************/

	/****************************************************************************************************
	 *
	 * initialize
	 *
	 ****************************************************************************************************/
	const color = isComplete ? 'success' : isSelect ? 'primary' : 'secondary';
	const able = !isAble;
	const display = !isVisible ? 'none' : undefined;

	/****************************************************************************************************
	 *
	 * handler
	 *
	 ****************************************************************************************************/

	/****************************************************************************************************
	 *
	 * util
	 *
	 ****************************************************************************************************/

	return (
		<MuiButton
			color={color}
			disabled={able}
			sx={{
				display: display,
				borderRadius: isTab ? '2.5rem' : '0.5rem',
			}}
			onClick={handleClick}
			{...props}
		>
			{startIcon}
			{children}
		</MuiButton>
	);
}

export default Button;
