import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import sessionReducer from './sessionReducer';
import searchReducer from './searchReducer';
import settingReducer from './settingReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
	customization: customizationReducer,
	session: sessionReducer,
	search: searchReducer,
	setting: settingReducer,
});

export default reducer;
