import { Link } from 'react-router-dom';
import { ButtonBase } from '@mui/material';
import U2PBS from '../../../assets/images/icons/u2PBS.png';

function LogoSection() {
	/****************************************************************************************************
	 *
	 * styles, dispatch
	 *
	 ****************************************************************************************************/

	/****************************************************************************************************
	 *
	 * state
	 *
	 ****************************************************************************************************/

	/****************************************************************************************************
	 *
	 * initialize
	 *
	 ****************************************************************************************************/

	/****************************************************************************************************
	 *
	 * handler
	 *
	 ****************************************************************************************************/

	/****************************************************************************************************
	 *
	 * util
	 *
	 ****************************************************************************************************/
	return (
		<ButtonBase
			disableRipple
			component={Link}
			to={''}
			sx={{ display: 'flex', justifyContent: 'flex-start', width: '16.875rem' }}
		>
			<img src={U2PBS} height={20} alt="logo" />
		</ButtonBase>
	);
}

export default LogoSection;
