import PropTypes from 'prop-types';
import { forwardRef } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';

// constant
const headerSX = {
	'& .MuiCardHeader-action': { mr: 0 },
};

/**
 * 메인 카드
 * @param {boolean} border 테두리 여부
 * @param {string} borderColor 테두리 색상
 * @param {boolean} boxShadow 카드 그림자
 * @param {boolean} hoverBoxShadow 호버 시 카드 그림자
 * @param {node} children 카드 내용
 * @param {boolean} content 본문 내용
 * @param {string} contentClass 본문내용 className string
 * @param {string} contentSX 본문내용 sx string
 * @param {boolean} darkTitle title스타일
 * @param {[PropTypes.node, PropTypes.string, PropTypes.object]} secondary action값
 * @param {string} shadow shadow css
 * @param {object} sx sx css
 * @param {[PropTypes.node, PropTypes.string, PropTypes.object]} title title 글자
 */
// ==============================|| CUSTOM MAIN CARD ||============================== //

const MainCard = forwardRef(
	(
		{
			border = true,
			borderColor = false,
			boxShadow,
			hoverBoxShadow,
			children,
			content = true,
			contentClass = '',
			contentSX = {},
			darkTitle,
			secondary,
			shadow,
			sx = {},
			title,
			...others
		},
		ref,
	) => {
		const theme = useTheme();

		return (
			<Card
				ref={ref}
				{...others}
				sx={{
					border: border ? '2px solid' : 'none',
					borderColor: borderColor ? borderColor : theme.palette.primary[200] + 75,
					boxShadow: boxShadow
						? shadow || '1px 2px 3px 0 rgba(93, 95, 239, 0.4)'
						: 'inherit',
					...sx,
				}}
			>
				{/* card header and action */}
				{!darkTitle && title && (
					<CardHeader sx={headerSX} title={title} action={secondary} />
				)}
				{darkTitle && title && (
					<CardHeader
						sx={headerSX}
						title={<Typography variant="h3">{title}</Typography>}
						action={secondary}
					/>
				)}

				{/* content & header divider */}
				{title && <Divider />}

				{/* card content */}
				{content && (
					<CardContent sx={contentSX} className={contentClass}>
						{children}
					</CardContent>
				)}
				{!content && children}
			</Card>
		);
	},
);

MainCard.propTypes = {
	border: PropTypes.bool,
	boxShadow: PropTypes.bool,
	children: PropTypes.node,
	content: PropTypes.bool,
	contentClass: PropTypes.string,
	contentSX: PropTypes.object,
	darkTitle: PropTypes.bool,
	secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
	shadow: PropTypes.string,
	sx: PropTypes.object,
	title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
};

export default MainCard;
