import CryptoES from 'crypto-es';

const keyAES256 = 'secretkeyu2cloud~!@#$%^&*()_++++';

var EncryptUtil = typeof exports === 'undefined' ? function numeric() {} : exports;
if (typeof global !== 'undefined') {
	global.EncryptUtil = EncryptUtil;
}

EncryptUtil.fctEncryptAES256 = function fctEncryptAES256(val) {
	const encrypted = CryptoES.AES.encrypt(val, keyAES256).toString();
	const b64 = CryptoES.enc.Base64.parse(encrypted);
	const hex = b64.toString(CryptoES.enc.Hex);
	return hex;
};

EncryptUtil.fctDecryptAES256 = function fctDecryptAES256(val) {
	const hex = CryptoES.enc.Hex.parse(val);
	const b64 = hex.toString(CryptoES.enc.Base64);
	const decrypted = CryptoES.AES.decrypt(b64, keyAES256);
	return decrypted.toString(CryptoES.enc.Utf8);
};

EncryptUtil.fctHash = function fctHash(val) {
	return CryptoES.SHA256(val).toString(CryptoES.enc.Base64);
};

EncryptUtil.fctEncodeToHex = function fctEncodeToHex(val) {
	return CryptoES.enc.Utf8.parse(val).toString(CryptoES.enc.Hex);
};

EncryptUtil.fctEncodeFromHex = function fctEncodeFromHex(val) {
	return CryptoES.enc.Hex.parse(val).toString(CryptoES.enc.Utf8);
};

export default EncryptUtil;
