import { Box, Typography } from '@mui/material';
import LogoSection from '../LogoSection';
import Profile from './Profile';
import { makeStyles } from '@mui/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { map } from 'lodash';

const useStyles = makeStyles((theme) => ({
	...theme.component.layout.mainLayout.header.base,
}));

const headerList = [
	{
		id: 1,
		title: '워크리스트',
		path: '/',
	},
	{
		id: 2,
		title: '결과입력',
		path: `/result`,
	},
	{
		id: 3,
		title: '설정',
		path: '/setting',
	},
];

const Header = () => {
	/****************************************************************************************************
	 *
	 * styles, dispatch
	 *
	 ****************************************************************************************************/
	const classes = useStyles();
	const navigate = useNavigate();
	const location = useLocation();
	const currentPath = location.pathname;

	/****************************************************************************************************
	 *
	 * state
	 *
	 ****************************************************************************************************/
	const [_tabId, setTabId] = useState(1);
	/****************************************************************************************************
	 *
	 * initialize
	 *
	 ****************************************************************************************************/
	useEffect(() => {
		// 메인 페이지 경로
		const mainPagePath = '/';

		// 결과 입력 페이지 경로
		const resultPagePath = '/result';

		// 설정 페이지 경로
		const settingPagePath = '/setting';

		if (currentPath === mainPagePath) {
			// 현재 페이지가 메인 페이지 경로일 때 실행할 코드
			setTabId(1);
		} else if (currentPath.startsWith(resultPagePath)) {
			// 현재 페이지가 결과 입력 페이지 경로로 시작할 때 실행할 코드
			setTabId(2);
		} else if (currentPath.startsWith(settingPagePath)) {
			// 현재 페이지가 결과 입력 페이지 경로로 시작할 때 실행할 코드
			setTabId(3);
		}
	}, [currentPath]);

	/****************************************************************************************************
	 *
	 * handler
	 *
	 ****************************************************************************************************/
	function handlerClick(item) {
		// 현재 페이지 경로 가져오기

		// 메인 페이지 경로
		const mainPagePath = '/';

		// 결과 입력 페이지 경로
		const resultPagePath = '/result';

		// 설정 페이지 경로
		const settingPagePath = '/setting';

		if (item === mainPagePath) {
			// 현재 페이지가 메인 페이지 경로일 때 실행할 코드
			setTabId(1);
			navigate(item);
		} else if (item.startsWith(resultPagePath)) {
			// 현재 페이지가 결과 입력 페이지 경로로 시작할 때 실행할 코드
			if (!window.sessionStorage.getItem('workListId')) {
				window.alert('이전 결과입력 수검자가 없습니다.');
			} else {
				setTabId(2);
				navigate(`/result/${window.sessionStorage.getItem('workListId')}`);
			}
		} else if (item.startsWith(settingPagePath)) {
			// 현재 페이지가 결과 입력 페이지 경로로 시작할 때 실행할 코드
			setTabId(3);
			navigate(item);
		}
	}

	/****************************************************************************************************
	 *
	 * util
	 *
	 ****************************************************************************************************/

	return (
		<>
			<Box className={classes.base}>
				<Box className={classes.logoContent} component="span">
					<LogoSection />
					<Box className={classes.linkContent}>
						{map(headerList, (item, index) => (
							<Typography
								key={index}
								variant={_tabId === item.id ? 'HeaderPageSelect' : 'HeaderPage'}
								onClick={() => handlerClick(item.path)}
							>
								{item.title}
							</Typography>
						))}
					</Box>
				</Box>
				<Profile />
			</Box>
		</>
	);
};

export default Header;
