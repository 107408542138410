import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
// import ReactGA from 'react-ga4';

import App from './App';
import { store } from './store';
import config from './config';

import CookieUtil from 'utils/CookieUtil';
import EncryptUtil from 'utils/EncryptUtil';
import U2CloudAPI from 'views/apis/U2CloudAPI';

import { ACCESSTOKEN } from 'store/constant';

var isLogin = false;
var isSubscription = false;

async function fctLoad() {
	let devToken;
	// let myInfo;
	let mySubscriptions;

	try {
		if (process.env.NODE_ENV === 'development') {
			devToken = await U2CloudAPI.LoginForDevelop('test@u2bio.com');

			CookieUtil.fctSetCookie(ACCESSTOKEN, devToken.data.token);
			// myInfo = (await U2CloudAPI.GetMyInfo()).data;

			isLogin = true;
		} else {
			// 배포모드일 경우 accessToken 이 정상적인지 검사
			if (!!CookieUtil.fctGetCookie('accessToken')) {
				let result = await U2CloudAPI.GetMyInfo();

				if (result.status === 200) {
					isLogin = true;
					// myInfo = result.data;
				} else {
					isLogin = false;
					CookieUtil.fctRemoveCookie('accessToken');
				}
			}
		}

		if (isLogin) {
			// 구독여부 확인
			mySubscriptions = (await U2CloudAPI.GetMySubscriptions()).data;

			if (!mySubscriptions || mySubscriptions.length === 0) {
				alert('PBS Screener 구독신청이 필요합니다');
			} else {
				isSubscription = true;
			}
		}
	} catch {
		CookieUtil.fctRemoveCookie('accessToken');
		isLogin = false;
	} finally {
	}

	if (!isLogin) {
		// 로그인 하지 않은 경우 사용자 포털로 이동
		window.open(
			`${process.env.REACT_APP_PORTAL}/login?redirect=${EncryptUtil.fctEncodeToHex(
				window.location.href,
			)}`,
			'_self',
		);
	} else if (!isSubscription) {
		// 구독신청하지 않은 경우 구독 신청 페이지로 이동
		window.open(`${process.env.REACT_APP_PORTAL}/service/subscription/edit`, '_self');
	} else {
		const root = ReactDOM.createRoot(document.getElementById('root'));

		root.render(
			<Provider store={store}>
				<BrowserRouter basename={config.basename}>
					<App />
				</BrowserRouter>
			</Provider>,
		);
	}
}

fctLoad();
