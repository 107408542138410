// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

export const SET_LOGON = '@session/SET_LOGON';
export const SET_MEMBERINFO = '@session/SET_MEMBERINFO';
export const SET_TEAMINFO = '@session/SET_TEAMINFO';
export const SET_ALERTINFO = '@session/SET_ALERTINFO';
export const SET_APPS = '@session/SET_APPS';
export const SET_SUBSCRIPTION = '@session/SET_SUBSCRIPTION';

export const SET_SEARCH = '@search/SET_SEARCH';

export const SET_DECISION_RELIABILITY = '@search/SET_DECISION_RELIABILITY';
export const SET_ALL_CELL_RELIABILITY = '@search/SET_ALL_CELL_RELIABILITY';
export const SET_IMAGE_RELIABILITY = '@search/SET_IMAGE_RELIABILITY';
export const SET_ALL_CELL_UNIT = '@search/SET_ALL_CELL_UNIT';
export const SET_IAMGE_ALIGN = '@search/SET_IAMGE_ALIGN';
