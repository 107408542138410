// import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

import Routes from './routes';
import themes from './themes';
import NavigationScroll from './layout/NavigationScroll';
// import CookieUtil from './utils/CookieUtil';
// import { ACCESSTOKEN } from 'store/constant';
// import Test from 'Test';

// ==============================|| APP ||============================== //

const App = () => {
	const customization = useSelector((state) => state.customization);

	// useEffect(() => {
	// 	return () => {
	// 		CookieUtil.fctRemoveCookie(ACCESSTOKEN);
	// 	};
	// }, []);
	

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={themes(customization)}>
				<CssBaseline />
				<NavigationScroll>
					<Routes />
				</NavigationScroll>
			</ThemeProvider>
		</StyledEngineProvider>
		// <Test></Test>
	);
};

export default App;
