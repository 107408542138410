import { lazy } from 'react';

import MainLayout from '../layout/MainLayout';
import Loadable from '../ui-component/Loadable';

const WorkList = Loadable(lazy(() => import('../views/WorkList')));
const Result = Loadable(lazy(() => import('../views/Result')));
const Setting = Loadable(lazy(() => import('../views/Setting')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
	path: '/',
	element: <MainLayout />,
	children: [
		{
			path: '/',
			element: <WorkList />,
		},
		{
			path: '/result/:workListId',
			element: <Result />,
		},
		{
			path: '/setting',
			element: <Setting />,
		},
	],
};

export default MainRoutes;
