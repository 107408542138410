import { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import {
	Box,
	Button as MuiButton,
	ClickAwayListener,
	Popper,
	Stack,
	Typography,
} from '@mui/material';

import { SET_LOGON } from '../../../../store/actions';
import CookieUtil from '../../../../utils/CookieUtil';
import { ProfileIcon, ProfileIcon2 } from '../../../../ui-component/icons/icons';
import MainCard from '../../../../ui-component/cards/MainCard';
import { ACCESSTOKEN } from 'store/constant';
import Button from 'ui-component/button/Button';
import Dialog from 'ui-component/dialogs/Dialog';

const useStyles = makeStyles((theme) => ({
	...theme.component.layout.mainLayout.header.profile,
}));

export default function Profile() {
	/****************************************************************************************************
	 *
	 * styles, dispatch
	 *
	 ****************************************************************************************************/
	const classes = useStyles();
	const session = useSelector((state) => state.session);
	const dispatch = useDispatch();

	/****************************************************************************************************
	 *
	 * state
	 *
	 ****************************************************************************************************/
	const [open, setOpen] = useState(false);
	const anchorRef = useRef(null);
	const [openAlert, setOpenAlert] = useState(null);

	/****************************************************************************************************
	 *
	 * initialize
	 *
	 ****************************************************************************************************/

	/****************************************************************************************************
	 *
	 * handler
	 *
	 ****************************************************************************************************/
	const handlerClickLogout = useCallback(async () => {
		CookieUtil.fctRemoveCookie(ACCESSTOKEN);
		setOpenAlert(true);
	}, []);

	const handlerAlert = useCallback(() => {
		setOpenAlert(null);
		dispatch({ type: SET_LOGON, success: false });
	}, [dispatch]);

	const handlerClickMyPage = useCallback(() => {
		window.open(`${process.env.REACT_APP_PORTAL}/mypage`, '_self');
		setOpen(false);
	}, []);

	const handlerClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	const handlerToggle = useCallback(() => {
		setOpen((prevOpen) => !prevOpen);
	}, []);

	/****************************************************************************************************
	 *
	 * util
	 *
	 ****************************************************************************************************/

	return (
		<>
			<MuiButton
				variant="text"
				onClick={handlerToggle}
				ref={anchorRef}
				disableRipple
				className={classes.nameButton}
			>
				{ProfileIcon2()}
				<Typography variant="HeaderPage">{`${session.memberInfo.memberName} 님`}</Typography>
			</MuiButton>
			<Popper
				placement="bottom-end"
				open={open}
				anchorEl={anchorRef.current}
				sx={{ paddingTop: '14px' }}
			>
				<ClickAwayListener onClickAway={handlerClose}>
					<MainCard
						border={false}
						elevation={16}
						content={false}
						boxShadow
						hoverBoxShadow
					>
						<Stack className={classes.content} spacing={2}>
							<Stack direction="row" spacing={2}>
								<Box className={classes.icon}>{ProfileIcon()}</Box>
								<Box className={classes.memberInfo}>
									<Typography variant="profileName">
										{session.memberInfo.memberName}
									</Typography>
									<Typography variant="profileEmail">
										{session.memberInfo.memberEmail}
									</Typography>
								</Box>
							</Stack>
							<Stack direction="row" spacing={1}>
								<Button fullWidth size="medium" onClick={handlerClickMyPage}>
									<Typography variant="button">내 정보</Typography>
								</Button>
								<Button fullWidth size="medium" onClick={handlerClickLogout}>
									<Typography variant="button">로그아웃</Typography>
								</Button>
							</Stack>
						</Stack>
					</MainCard>
				</ClickAwayListener>
			</Popper>

			{!!openAlert && (
				<Dialog onClose={handlerAlert}>{'U2Cloud 로그아웃이 완료되었습니다.'}</Dialog>
			)}
		</>
	);
}
