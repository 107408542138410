import SCoreDreamRegular from '../assets/fonts/SCDream3.otf';
import SCoreDreamMedium from '../assets/fonts/SCDream4.otf';
import SCoreDreamBold from '../assets/fonts/SCDream5.otf';
import NanumRegular from '../assets/fonts/NanumSquareRoundOTFR.otf';
import NanumBold from '../assets/fonts/NanumSquareRoundOTFB.otf';
import NanumExtraBold from '../assets/fonts/NanumSquareRoundOTFEB.otf';
import RobotoRegular from '../assets/fonts/Roboto-Regular.ttf';
import RobotoMedium from '../assets/fonts/Roboto-Medium.ttf';
import RobotoBold from '../assets/fonts/Roboto-Bold.ttf';

let fontLoading = `
@font-face {
	font-family: 'S-Core Dream';
	font-weight: 400;
	src: url(${SCoreDreamRegular}) format('opentype');
	unicode-range: U+AC00-D7A3, U+0030-0039, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E
}
@font-face {
	font-family: 'S-Core Dream';
	font-weight: 500;
	src: url(${SCoreDreamMedium}) format('opentype');
	unicode-range: U+AC00-D7A3, U+0030-0039, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E
}
@font-face {
	font-family: 'S-Core Dream';
	font-weight: 700;
	src: url(${SCoreDreamBold}) format('opentype');
	unicode-range: U+AC00-D7A3, U+0030-0039, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E
}

@font-face {
	font-family: 'NanumSquareRound';
	font-weight: 500;
	src: url(${NanumBold}) format('opentype')
}
@font-face {
	font-family: 'NanumSquareRound';
	font-weight: 900;
	src: url(${NanumExtraBold}) format('opentype')
}
@font-face {
	font-family: 'NanumSquareRound';
	font-weight: 300;
	src: url(${NanumRegular}) format('opentype')
}

@font-face {
	font-family: 'Roboto';
	font-weight: 400;
	src: url(${RobotoRegular}) format('opentype');
	unicode-range: U+0041-005A, U+0061-007A
}
@font-face {
	font-family: 'Roboto';
	font-weight: 500;
	src: url(${RobotoMedium}) format('opentype');
	unicode-range: U+0041-005A, U+0061-007A
}
@font-face {
	font-family: 'Roboto';
	font-weight: 700;
	src: url(${RobotoBold}) format('opentype');
	unicode-range: U+0041-005A, U+0061-007A
}
`;

export default fontLoading;
