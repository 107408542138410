// action - state management
import * as actionTypes from './actions';

export const initialState = {
	decisionReliability: [70, 100],
	allCellReliability: [70, 100],
	imageReliability: [70, 100],
	allCellUnit: 100,
	imageAlign: 'DESC',
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const settingReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_DECISION_RELIABILITY:
			return {
				...state,
				decisionReliability: action.value,
			};

		case actionTypes.SET_ALL_CELL_RELIABILITY:
			return {
				...state,
				allCellReliability: action.value.allCellReliability,
			};

		case actionTypes.SET_IMAGE_RELIABILITY:
			return {
				...state,
				imageReliability: action.value,
			};

		case actionTypes.SET_ALL_CELL_UNIT:
			return {
				...state,
				allCellUnit: action.value,
			};

		case actionTypes.SET_IAMGE_ALIGN:
			return {
				...state,
				imageAlign: action.value,
			};

		default:
			return state;
	}
};

export default settingReducer;
